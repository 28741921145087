// src/App.js
import React from 'react';

import ImageEditor from './components/ImageEditor';
import ImageProcessing from './components/ImageProcessing';
import L from './components/logic';
import Place2 from './components/place2';
import Placement from './components/placement';
import FASTSAM from './components/fastsam';
// import ImageForm from './components/ImageForm';



const commonStyle = {
  // backgroundImage: 'linear-gradient(to bottom right, #CB5EEE, #4BE1EC)',
  backgroundImage: 'linear-gradient(to bottom right, #2F80ED, #B2FFDA)',
  minHeight: '100vh',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '14px',
  border: '1px solid white', // Add border for better visibility
};
// REACT_APP_URL_BACKEND


function App() {
  const backendUrl = process.env.REACT_APP_URL_BACKEND
  return (

    <div style={commonStyle}>
          <button style={{
         padding: '20px', // Adjust the font size as needed
        }}>
            
            <a href={backendUrl} class="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"><span class="relative text-base font-semibold text-white">Rentuhbin Backend</span></a>
           </button>
    <div style={{ ...commonStyle, marginTop: '20px', marginBottom: '40px' }}>
        <h2 style={{ color: 'black',fontWeight: 'bold' }}>FastSam Endpoint image</h2>
        <FASTSAM />
      </div>
      {/* Version 1 */}
      <div style={{ ...commonStyle, marginTop: '20px', marginBottom: '40px' }}>
        <h2 style={{ color: 'black',fontWeight: 'bold' }}>Combined Endpoint image</h2>
        <ImageEditor />
      </div>


      {/* Version 2 */}
      <div style={{ ...commonStyle, marginTop: '20px', marginBottom: '40px' }}>
        <h2 style={{ color: 'black',fontWeight: 'bold' }}>SAM Endpoint</h2>
        <ImageProcessing />
      </div>
      {/* Version 4 */}
      <div style={{ ...commonStyle, marginTop: '20px', marginBottom: '40px' }}>
        <h2 style={{ color: 'black',fontWeight: 'bold' }}>Placement Endpoint</h2>
        <Placement />
      </div>
      {/* Version 1 */}
      <div style={{ ...commonStyle, marginTop: '20px', marginBottom: '40px' }}>
        <h2 style={{ color: 'black',fontWeight: 'bold' }}>Place2 Endpoint</h2>
        <Place2 />
      </div>

      {/* Version 4 */}
      <div style={{ ...commonStyle, marginTop: '20px', marginBottom: '40px' }}>
        <h2 style={{ color: 'black',fontWeight: 'bold' }}> Kmeans clustering to detect purple pixel color of image</h2>
        <L />
      </div>
    </div>
  );
}

export default App;



