import React, { useState } from 'react';
import axios from 'axios';

const PlacementForm = () => {
  const [orgImage, setOrgImage] = useState(null);
  const [bgImage, setBgImage] = useState(null);
  const [dumpsterLen, setDumpsterLen] = useState(22);
  const [dumpsterWidth, setDumpsterWidth] = useState(8);
  const [scaleX, setScaleX] = useState(65);
  const [scaleY, setScaleY] = useState(65);
  const [imageXPixels, setImageXPixels] = useState(300);
  const [imageYPixels, setImageYPixels] = useState(300);
  const [xCoordinate, setXCoordinate] = useState(152);
  const [yCoordinate, setYCoordinate] = useState(230);
  const [tilt, setTilt] = useState(70);
  const [result, setResult] = useState({ status_message: '', placeable: '', image:'' });

  const handleOrgImageChange = (event) => {
    setOrgImage(event.target.files[0]);
  };

  const handleBgImageChange = (event) => {
    setBgImage(event.target.files[0]);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('org_image', orgImage);
      formData.append('bg_image', bgImage);
      formData.append('dumpster_len_in_feet', dumpsterLen);
      formData.append('dumpster_width_in_feet', dumpsterWidth);
      formData.append('scale_x_in_meters', scaleX);
      formData.append('scale_y_in_meters', scaleY);
      formData.append('image_x_pixels', imageXPixels);
      formData.append('image_y_pixels', imageYPixels);
      formData.append('x_coordinate', xCoordinate);
      formData.append('y_coordinate', yCoordinate);
      formData.append('tilt', tilt);

      const endpoint = 'api/v1/placement';
      const baseUrl= process.env.REACT_APP_URL_BACKEND;
      const apiUrl = baseUrl + endpoint;
      console.log(apiUrl);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResult(response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      background: 'rgba(255, 255, 255, 0.3)',
      padding: '20px',
      borderRadius: '14px',
      boxShadow: '0 4px 8px rgba(10, 10, 10, 0.5)',
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)',
    }}>
      <div>
        <h2>Placement Form</h2>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Org Image:</label>
          <input type="file" accept="image/*" onChange={handleOrgImageChange} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Bg Image:</label>
          <input type="file" accept="image/*" onChange={handleBgImageChange} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Dumpster Length (feet):</label>
          <input type="number" value={dumpsterLen} onChange={(e) => setDumpsterLen(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Dumpster Width (feet):</label>
          <input type="number" value={dumpsterWidth} onChange={(e) => setDumpsterWidth(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Scale X (meters):</label>
          <input type="number" value={scaleX} onChange={(e) => setScaleX(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Scale Y (meters):</label>
          <input type="number" value={scaleY} onChange={(e) => setScaleY(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Image X Pixels:</label>
          <input type="number" value={imageXPixels} onChange={(e) => setImageXPixels(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Image Y Pixels:</label>
          <input type="number" value={imageYPixels} onChange={(e) => setImageYPixels(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>X Coordinate:</label>
          <input type="number" value={xCoordinate} onChange={(e) => setXCoordinate(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Y Coordinate:</label>
          <input type="number" value={yCoordinate} onChange={(e) => setYCoordinate(e.target.value)} />
        </div>
        <div>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>Tilt:</label>
          <input type="number" value={tilt} onChange={(e) => setTilt(e.target.value)} />
        </div>
  
        <button
                style={{
                backgroundColor: '#4CAF50',
                color: 'white',
                padding: '10px 20px',
                border: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: '15px',  // Adjust the margin-top value as needed
                }}
                onClick={handleSubmit}
                >
                Submit
                </button>

      </div>
      <div>
        <h2>Status Message:</h2>
        <p style={{ color: 'grey', display: 'inline' }}>{result && result.status_message}</p>
        <h2>Placeable:</h2>
        <p style={{ color: 'grey', display: 'inline' }}>{result && `${result.placeable}`}</p>
        <h2>Backend Processed Image:</h2>
        <div>
          {result && (
            <img
              src={`data:image/png;base64,${result.image}`}
              alt="Processed Image"
              style={{ maxWidth: '100%', marginTop: '20px', borderRadius: '14px' }}
            />
          )}
        </div>
      </div>
    </div>
  );
  
};

export default PlacementForm;
