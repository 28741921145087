import React, { useState } from 'react';

const PlaceObjectForm = () => {
  const [file, setFile] = useState(null);
  const [height, setHeight] = useState(22);
  const [width, setWidth] = useState(8);
  const [xCoordinate, setXCoordinate] = useState(290);
  const [yCoordinate, setYCoordinate] = useState(163);
  const [tilt, setTilt] = useState(70);
  const [result, setResult] = useState(null);
  const [uid, setUid] = useState('');
  const [inputValues, setInputValues] = useState({
    height_in_feet: 22,
    width_in_feet: 8,
    x_coordinate: null,
    y_coordinate: null,
    image_width_pixels: null,
    image_height_pixels: null,
    tilt: null,
  });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleUidChange = (event) => {
    setUid(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      formData.append('org_image', file);
      formData.append('uid', uid);
      formData.append('image_x_pixels', inputValues.image_width_pixels || 400);
      formData.append('image_y_pixels', inputValues.image_height_pixels || 400);
      formData.append('scale_x_in_meters', 65);
      formData.append('scale_y_in_meters', 65);
      formData.append('dumpster_len_in_feet', 22);
      formData.append('dumpster_width_in_feet', 8);
      formData.append('x_coordinate', xCoordinate);
      formData.append('y_coordinate', yCoordinate);
      formData.append('tilt', tilt);
      const endpoint = 'api/v1/place2';
      const baseUrl= process.env.REACT_APP_URL_BACKEND;
      const apiUrl = baseUrl + endpoint;
      console.log(apiUrl);

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setResult(data);
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


    return (
      <div
        style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',  // Center align the items horizontally
        gap: '20px',  // Add a gap of 10 pixels between child elements
        background: 'rgba(255, 255, 255, 0.3)',
        padding: '20px',
        borderRadius: '14px',
        boxShadow: '0 4px 8px rgba(10, 10, 10, 0.5)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
      }}
      >
        <h2 style={{ marginBottom: '20px', color: '#333' }}>Place Object Form</h2>
  
        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          Select Image:
          <input type="file" accept="image/*" onChange={handleFileChange} style={{ marginTop: '5px' }} />
        </label>
        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          UID :
            <input
            type="text"
            value={uid}
            onChange={handleUidChange}
            style={{ marginLeft: '10px' }}
            />
      </label>
  
        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          Height (feet):
          <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} style={{ marginTop: '5px' }} />
        </label>
  
        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          Width (feet):
          <input type="number" value={width} onChange={(e) => setWidth(e.target.value)} style={{ marginTop: '5px' }} />
        </label>
  
        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          X Coordinate:
          <input type="number" value={xCoordinate} onChange={(e) => setXCoordinate(e.target.value)} style={{ marginTop: '5px' }} />
        </label>
  
        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          Y Coordinate:
          <input type="number" value={yCoordinate} onChange={(e) => setYCoordinate(e.target.value)} style={{ marginTop: '5px' }} />
        </label>
  
        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          Tilt:
          <input type="number" value={tilt} onChange={(e) => setTilt(e.target.value)} style={{ marginTop: '5px' }} />
        </label>
  
        <button
          onClick={handleSubmit}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            marginTop: '15px',
          }}
        >
          Submit
        </button>
  
        {result && (
          <div style={{ marginTop: '20px' }}>
            <h3 style={{ color: '#333' }}>Result:</h3>
            <p style={{ color: '#555' }}>Status Message: {result.status_message}</p>
            <p style={{ color: '#555' }}>Placeable: {result.placeable}</p>
            <img src={`data:image/png;base64, ${result.image}`} alt="Processed" style={{ marginTop: '10px', borderRadius: '8px' }} />
          </div>
        )}
      </div>
    );
  };
  
  export default PlaceObjectForm;
  
