import React, { useState, useRef, useEffect } from 'react';
import '../index.css';


const FastSam = () => {
  const [coordinates, setCoordinates] = useState({ x: null, y: null });
  const [image, setImage] = useState(null);
  const [rectangleTilt, setRectangleTilt] = useState(0);
  const [file, setFile] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [processedImageUrl, setProcessedImageUrl] = useState(null);
  const [uid, setUid] = useState('');
  const [result, setResult] = useState({ status_message: '', placeable: '', image:'' });

  const [inputValues, setInputValues] = useState({
    height_in_feet: 4,
    width_in_feet: 4,
    x_coordinate: null,
    y_coordinate: null,
    image_width_pixels: null,
    image_height_pixels: null,
    tilt: null,
  });

  const canvasRef = useRef(null);
  const imageInputRef = useRef(null);
  const updateCanvasSize = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const img = new Image();

    // Set canvas size based on user input
    canvas.width = inputValues.image_width_pixels;
    canvas.height = inputValues.image_height_pixels;

    img.src = image;

    img.onload = () => {
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      const resizedImageUrl = canvas.toDataURL('image/jpeg');
      setImage(resizedImageUrl);
    };
  };
  const handleImageWidthChange = (event) => {
    const width = Number(event.target.value);
    setInputValues((prevInputValues) => ({ ...prevInputValues, image_width_pixels: width }));
    // setFile((prevFile) => (prevFile ? new File([prevFile], prevFile.name) : null));
    updateCanvasSize()
    // updateCanvasSize(inputValues.image_height_pixels, width);
  };
  const handleUidChange = (event) => {
    setUid(event.target.value);
  };

  const handleImageHeightChange = (event) => {
    const height = Number(event.target.value);
    setInputValues((prevInputValues) => ({ ...prevInputValues, image_height_pixels: height }));
    // setFile((prevFile) => (prevFile ? new File([prevFile], prevFile.name) : null));
    updateCanvasSize()
    // updateCanvasSize(height, inputValues.image_width_pixels);
  };
  

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
  
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);
  
    img.onload = () => {
      const canvas = document.createElement('canvas');
  
      // Use user-entered values for canvas dimensions
      canvas.width = inputValues.image_width_pixels;
      canvas.height = inputValues.image_height_pixels;
  
      const context = canvas.getContext('2d');
  
      // Draw the image on the canvas
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
  
      const resizedImageUrl = canvas.toDataURL('image/jpeg');
      setImage(resizedImageUrl);
    };
  
    img.onerror = (error) => {
      console.error('Error loading image:', error);
    };
  
    setFile(selectedImage);
  };
  

  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setCoordinates({ x, y });
  };

  const handleImageClick = async () => {
    if (!file) {
      console.error('No image file selected.');
      return;
    }
  
    const w = 3;
    const h = 39;
  
    const adjustedX = Math.round(coordinates.x - w / 2);
    const adjustedY = Math.round(coordinates.y - h / 2);
    const tiltForApi = (rectangleTilt + 90) % 360;
    console.log("OX",adjustedX)
    console.log("OY",adjustedY)
  
    try {
      const formData = new FormData();
      formData.append('org_image', file);
      formData.append('uid', uid);
      formData.append('image_x_pixels', inputValues.image_width_pixels || 400);
      formData.append('image_y_pixels', inputValues.image_height_pixels || 400);
      formData.append('scale_x_in_meters', 65);
      formData.append('scale_y_in_meters', 65);
      formData.append('dumpster_len_in_feet',22);
      formData.append('dumpster_width_in_feet', 8);
      formData.append('x_coordinate', adjustedX);
      formData.append('y_coordinate', adjustedY);
      formData.append('tilt', tiltForApi);
      const endpoint = 'api/v1/fastsam';
      const baseUrl= process.env.REACT_APP_URL_BACKEND;
      const apiUrl = baseUrl + endpoint;
      console.log(apiUrl);
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
      });
  
      const resultData = await response.json();
      setResult(resultData);
      
      // setProcessedImage(`data:image/png;base64, ${resultData.image}`);
     
  
      if (resultData.placeable === true) {
        // Draw rectangle on a new canvas
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
  
        const rectWidth = 42;
        const rectHeight = 16;
 

  
        const img = new Image();
        img.src = URL.createObjectURL(file); // Use original image URL
  
        img.onload = () => {
            // Set canvas size to match the original image size
            canvas.width = inputValues.image_width_pixels;
            canvas.height = inputValues.image_height_pixels;
            
            console.log("IX",adjustedX)
            console.log("IY",adjustedY)
          
            // Draw the image
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
          
            // Apply transformations and draw the rectangle
            context.translate(adjustedX, adjustedY);
            context.rotate((rectangleTilt * Math.PI) / 180);
            context.strokeStyle = 'rgb(0, 255, 0, 1)';
            context.lineWidth = 1.5;
            context.strokeRect(-rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight);
          
            // Convert canvas to data URL and set it as processed image
            const processedImageUrl = canvas.toDataURL('image/jpeg');
            setProcessedImageUrl(processedImageUrl);
          };
      }
      else {
        // Handle the case when resultData.placeable is not 'True'
        // For example, clear the processed image
        setProcessedImageUrl(null);
      }
      
    } catch (error) {
      console.error('Error from server:', error.response);
      // Handle the error here
    }
  };
          useEffect(() => {
            // Draw the processed image on the canvas if available
            if (processedImageUrl) {
              const canvas = canvasRef.current;
              const context = canvas.getContext('2d');
        
              const img = new Image();  // <-- Define 'img'
              img.src = processedImageUrl;  // <-- Use 'processedImageUrl' or another source if applicable

              img.onload = () => {
                canvas.width = inputValues.image_width_pixels;
                canvas.height = inputValues.image_height_pixels;
                context.drawImage(img, 0, 0, canvas.width, canvas.height);
              };
            }
          }, [processedImageUrl]);

  const handleRectangleTiltChange = (event) => {
    setRectangleTilt(Number(event.target.value));
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',  // Center align the items horizontally
      gap: '20px',  // Add a gap of 10 pixels between child elements
      background: 'rgba(255, 255, 255, 0.3)',
      padding: '20px',
      borderRadius: '14px',
      boxShadow: '0 4px 8px rgba(10, 10, 10, 0.5)',
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)',
    }}>
      <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      Image Width (pixels):
      <input
        type="number"
        value={inputValues.image_width_pixels || ''}
        onChange={handleImageWidthChange}
        style={{ marginLeft: '10px' }}
      />
      </label>
    
    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      Image Height (pixels):
      <input
        type="number"
        value={inputValues.image_height_pixels || ''}
        onChange={handleImageHeightChange}
        style={{ marginLeft: '10px' }}
      />
      </label> 
      <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        UID:
        <input
          type="text"
          value={uid}
          onChange={handleUidChange}
          style={{ marginLeft: '10px' }}
        />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          Select Image:
          <input type="file" accept="image/*" onChange={handleImageChange} style={{ marginTop: '5px' }} ref={imageInputRef}/>
        </label>   

      {image && (
        <div>
          <img
            src={image}
            alt="Resized Image"
            style={{
              maxWidth: '100%',
              marginTop: '20px',
            }}
          />
        </div>
      )}

      <div>
        Rectangle Tilt:
        <input
          type="range"
          style={{ marginTop: '30px' }}
          className="tilt-slider"
          min="-90"
          max="90"
          value={rectangleTilt}
          onChange={handleRectangleTiltChange}
        />

        Tilt Value: {rectangleTilt}°
      </div>

      {image && (
        <div style={{ position: 'relative', display: 'inline-block', marginTop: '50px' }}>
          <img
            src={image}
            alt="Uploaded Image"
            onMouseMove={handleMouseMove}
            onClick={handleImageClick}
            style={{
              maxWidth: '100%',
              cursor: 'crosshair',
            }}
          />
          {coordinates.x !== null && (
            <div style={{
              position: 'absolute',
              border: '2px solid red',
              width: '42px',
              height: '16px',
              top: coordinates.y - 30,
              left: coordinates.x - 25,
              transform: `rotate(${rectangleTilt}deg)`,
            }}></div>
          )}
        </div>
      )}

      {coordinates.x !== null && (
        <div>
          Coordinates: X: {coordinates.x}, Y: {coordinates.y}
        </div>
      )}

      {inputValues.x_coordinate !== null && (
        <div>
          <h2>Input Values:</h2>
          <p>Height (feet): {inputValues.height_in_feet}</p>
          <p>Width (feet): {inputValues.width_in_feet}</p>
          <p>X-coordinate: {inputValues.x_coordinate}</p>
          <p>Y-coordinate: {inputValues.y_coordinate}</p>
          <p>Tilt: {inputValues.tilt}°</p>
        </div>
      )}

<div>
  <h2>Status Message:</h2>
  <p style={{ color: 'grey', display: 'inline' }}>{result && result.status_message}</p>
  <h2>Placeable:</h2>
  <p style={{ color: 'grey', display: 'inline' }}>{result && `${result.placeable}`}</p>
  <h2>Backend proccessed image:</h2>
  <div>
          {result && (
            <img
              src={`data:image/png;base64,${result.image}`}
              alt="Processed Image"
              style={{ maxWidth: '100%', marginTop: '20px',marginTop: '10px', borderRadius: '14px' }
            }
            />
          )}
        </div>
  
  
</div>
<h2>Frontend proccessed image:</h2>
<canvas
        ref={canvasRef}
        style={{
          display: processedImageUrl ? 'block' : 'none',
          maxWidth: '100%',
          marginTop: '20px',
          marginTop: '10px', borderRadius: '14px'
        }}
      />

    </div>
  );
};

export default FastSam;